/**
 * Functions for sub navigation
 **/

var lang;
var openText;
var backText;
var navigationSections = [];
var timeoutDefault = 300; // do not set under 300

var loadingSpinner = document.createElement('div')
loadingSpinner.classList.add('spinner-border', 'text-primary')
loadingSpinner.setAttribute('role', 'status')
loadingSpinner.innerHTML = '<span class="visually-hidden">Loading...</span>'

document.addEventListener('DOMContentLoaded', function (event) {
  const submenuCollapsibles = document.querySelectorAll('.main-menu-item .submenu');

  submenuCollapsibles.forEach((menu) => {
    menu.addEventListener('show.bs.collapse', event => {
      document.body.classList.add('submenu-open')
      updateCommonVariables(event.target);
      getNavigation(event.target, false)
    })

    menu.addEventListener('hidden.bs.collapse', event => {
      if (document.querySelectorAll('.submenu.show, .submenu.collapsing').length === 0) {
        document.body.classList.remove('submenu-open')
      }

      event.target.querySelectorAll('.submenu-container').forEach((el) => el.remove())
    })
  })

  document.addEventListener('click', (e) => {
    if (!document.body.classList.contains('mobile-nav-open') && !e.target.classList.contains('submenu-element')) {
      closeCollapses()
    }
  })

  document.addEventListener('keyup', handleEscape);
})

/**
 * Close collapses
 */

export function closeCollapses() {
  var mainmenuList = document.querySelector('#main-menu-list')
  mainmenuList.querySelectorAll('.collapse').forEach((c) => { c.classList.remove('show') })
  mainmenuList.querySelectorAll('.main-menu-item .menu-toggle').forEach((c) => { c.classList.add('collapsed') })
  document.querySelectorAll('.submenu-wrapper div').forEach((el) => el.remove())
  document.body.classList.remove('submenu-open')
} 

var handleEscape = (event) => {
  var key = event.which || event.keyCode;
  if (!isMobile() && key === 27 && document.body.classList.contains('submenu-open')) {
    closeCollapses()
    event.stopPropagation()
  }
};

/**
 * Sets Common used API variables.
 * 
 * @param {Element} el 
 */ 
function updateCommonVariables(el) {
  if (!lang) {
    lang = el.getAttribute('data-oaj-lang')
  }

  if (!openText) {
    openText = el.getAttribute('data-oaj-open-text')
  }

  if (!backText) {
    backText = el.getAttribute('data-oaj-nav-back')
  }
}

/**
 * Helper to find out if mobile layout
 */

function isMobile() {
  return window.innerWidth < 992
}

/**
 * Gets more levels after first one is opened.
 * 
 * @param {Element} el 
 */ 
function getmore(el) {
  getNavigation(el.target, true)
}

/**
 * Creates navigation li items
 * 
 * @param {NavItem} item 
 */
function createNavItem(item) {
  var li = document.createElement('li')
  li.classList.add('mb-4', 'd-flex', 'align-items-center', 'justify-content-between', 'submenu-element')

  var a = document.createElement('a')
  a.href = item.pageUrl
  a.classList.add('submenu-item', 'text-md', 'submenu-element')
  a.innerText = item.pageTitle
  a.setAttribute('data-oaj-pageid', item.pageId)
  li.append(a)

  if (item.hasChildren) {
    var loadMoreBtn = document.createElement('button')
    loadMoreBtn.classList.add('border-0', 'bg-transparent', 'menu-toggle', 'submenu-element')
    loadMoreBtn.onclick = getmore
    loadMoreBtn.setAttribute('aria-label', openText.replace('{0}', item.pageTitle))
    loadMoreBtn.setAttribute('data-oaj-pageid', item.pageId)
    li.append(loadMoreBtn)
  }

  return li
}

/**
 * Removes current additional layer
 * 
 * @param {Element} el 
 */
function removeLayer(el) {

  var layerToRemove = el.target.closest('.additional')
  
  if (isMobile() && !layerToRemove) {
    layerToRemove = el.target.closest('.submenu-container')
  }
  layerToRemove.classList.add('right')

  var layerToShow = layerToRemove.previousElementSibling

  if (layerToShow) {
    layerToShow.classList.remove('d-none')
    setTimeout(() => {
      layerToShow.classList.remove('left')
      layerToShow.setAttribute('aria-hidden', 'false')
    }, timeoutDefault - 250)
    

    setTimeout(() => {
      layerToShow.querySelector('.title').focus()
    }, timeoutDefault + 150)

  } else {
    closeCollapses()
  }

  setTimeout(() => { layerToRemove.remove() }, timeoutDefault + 50)  
}

/**
 * Creates back button to additional layers 
 */
function createBackBtn() {

  var btn = document.createElement('button')
  btn.classList.add('back-btn', 'submenu-element')
  btn.onclick = removeLayer
  btn.innerText = backText

  return btn
}

/**
 * Gets target element where to inject navigation
 * 
 * @param {Element} el 
 */
function getTarget(el) {
  var targetElement

  if (!el.classList.contains('submenu')) {
    targetElement = el.closest('.submenu-wrapper')
  } else {
    targetElement = el.querySelector('.submenu-wrapper')
  }

  return targetElement
}

/**
 * Gets section heading text
 * 
 * @param {Element} el 
 */
function getSectionHeadingText(el) {

  var heading = document.createElement('div')
  heading.classList.add('title')
  heading.setAttribute('tabindex', '-1')

  var text = el.getAttribute('data-oaj-section-name')

  if (!text) {
    text = el.previousSibling.innerText
  }

  heading.innerText = text 

  return heading
}

/**
 * Creates Navigation UL element with LIs
 * 
 * @param {Element} target Element where to inject list 
 * @param items List from API
 * @param {Element} el calling element
 * @param {boolean} isAdditionalLayer Is additional ul after first ul
 */ 
function createNavigationList(targetElement, items, el, isAdditionalLayer) {

  if (!el.classList.contains('submenu')) {
    targetElement = el.closest('.submenu-wrapper')
  } else {
    targetElement = el.querySelector('.submenu-wrapper')
  }

  var div = document.createElement('div')
  div.classList.add('submenu-container', 'submenu-element', 'px-5', 'px-lg-0')

  var ul = document.createElement('ul')
  ul.classList.add('list-style-none', 'ps-0', 'submenu-element')

  items.forEach((item) => {
    ul.append(createNavItem(item))
  })

  div.append(createBackBtn())
  div.append(getSectionHeadingText(el))

  if (isAdditionalLayer || isMobile()) {
    div.classList.add('additional')
    div.classList.add('right');
    targetElement.querySelectorAll('div:not(.left):not(.title)').forEach(el => {
      el.classList.add('left')
      el.setAttribute('aria-hidden', 'true')
    })
  }

  div.append(ul)

  var spinner = targetElement.querySelector('.spinner-border')
  if (spinner) spinner.remove()

  targetElement.append(div)

  setTimeout(() => {
    div.classList.remove('right')
  }, timeoutDefault - 200)

  setTimeout(() => {
    targetElement.querySelector('.submenu-container:not(.left) .title').focus()
    targetElement.querySelectorAll('.submenu-container.left').forEach(el => {
      el.classList.add('d-none')
    })
  }, timeoutDefault + 100)
}

/**
 * Gets Navigation using API
 * 
 * @param {Element} el Can be button or bs collapsible element.
 * @param {boolean} isAdditionalLayer Is additional ul after first ul.
 */
function getNavigation(el, isAdditionalLayer) {
  var pageid = el.getAttribute('data-oaj-pageid')
  var itemList = navigationSections.find((i) => i.id === pageid)
  var targetElement = getTarget(el)

  if (itemList?.items) {
    createNavigationList(targetElement, itemList.items, el, isAdditionalLayer)
  } else {
    targetElement.append(loadingSpinner)
    const request = new Request(`/api/navigation/pages/${pageid}/${lang}`);

    fetch(request)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Problem occured when getting navigation!");
        }
      })
      .then((response) => {
        navigationSections.push({ id: pageid, items: response })
        createNavigationList(targetElement, response, el, isAdditionalLayer)
      })
      .catch((error) => {
        console.error(error);
      });
  }
}